import React, { forwardRef, useImperativeHandle } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    displayToast(
      type = 'info',
      message = ['This is a toast'],
      autoClose = '5000',
      position = 'top-right',
      closeOnClick = true,
    ) {
      if (typeof message === 'string') {
        message = [message];
      }
      message.forEach((msg) => {
        toast[type](msg, {
          position,
          autoClose,
          closeOnClick
        });
      });
    }
  }));
  return (
    <>
      <ToastContainer/>
    </>
  );
});

export default Toast;
